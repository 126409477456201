import validate from "D:/GIT/handelskontor/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "D:/GIT/handelskontor/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "admin-only": () => import("D:/GIT/handelskontor/middleware/admin-only.js"),
  "guest-only": () => import("D:/GIT/handelskontor/middleware/guest-only.js"),
  "user-only": () => import("D:/GIT/handelskontor/middleware/user-only.js")
}