let counter = 0;

export const useNotification = () => {
    let timeout = null;

    const notificationStore = useState('useNotification', () => []);

    const addNotification = (notification) => {
        notification.id = counter++;
        notification.disabled = false;
        notificationStore.value.push(notification);

        setTimeout(() => {
            removeNotification(notification.id);
        }, notification.delay || 9000);
    };

    const removeNotification = (id) => {
        clearTimeout(timeout);
        const index = notificationStore.value.findIndex((o) => o.id === id);
        if (index !== -1) {
            (notificationStore.value[index].disabled = true),
                (timeout = setTimeout(() => {
                    if (notificationStore.value.every((o) => o.disabled === true)) {
                        notificationStore.value = [];
                    }
                }, 1000));
        }
    };

    const noFunction = () => {
        addNotification({
            type: 'danger',
            label: 'Funktion in Arbeit',
            text: 'Diese Funktion steht aktuell nicht zur Verfügung.',
        });
    };

    return {
        notificationStore,
        addNotification,
        removeNotification,
        noFunction,

        // short
        store: notificationStore,
        add: addNotification,
        remove: removeNotification,
    };
};
